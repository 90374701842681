module.exports = {
  buttonLinks: {
    login: "https://app.cloudspendbot.com/auth",
    signup: "https://api.cloudspendbot.com/install",
    statusPage: "https://cloudspendbot.instatus.com/",
  },
  menu: [
    { link: "/pricing", title: "Pricing" },
    { link: "/faq", title: "FAQs" },
    {
      link: "https://cloudspendbot.instatus.com/",
      title: "Status",
      target: "_blank",
    },
  ],
  footer: [
    { title: "Pricing", link: "/pricing" },
    { title: "FAQs", link: "/faq" },
    {
      title: "Status page",
      link: "https://cloudspendbot.instatus.com/",
      target: "_blank",
    },
    { title: "Terms of service", link: "/terms-of-service" },
    { title: "Privacy policy", link: "/privacy-policy" },
  ],
}
