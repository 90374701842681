import * as React from "react"
import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import Logo from "../../images/logo.svg"
import { menu, buttonLinks } from "../internalConfig"

export default function Navbar() {
  return (
    <div className="min-h-full">
      <div className=" border-b-2 border-gray-100 container max-w-full top-0 z-50 ">
        <Popover className="z-40 relative bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="/">
                  <span className="sr-only">Meet for Slack</span>
                  <img
                    className="h-8 sm:h-8 lg:h-10 w-auto rounded-md"
                    src={Logo}
                    alt=""
                  />
                </a>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-10">
                {menu.map(item => {
                  return (
                    <a
                      key={item.title}
                      target={item?.target}
                      href={item.link}
                      className="text-base font-medium text-gray-500 hover:text-gray-900"
                    >
                      {item.title}
                    </a>
                  )
                })}
              </Popover.Group>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <a
                  href={buttonLinks.login}
                  className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow rounded-md shadow-sm text-base font-medium text-indigo-600 bg-white hover:bg-gray-50"
                >
                  Log in
                </a>
                <a
                  href={buttonLinks.signup}
                  className="ml-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Sign up
                </a>
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={Logo} alt="" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                    {menu.map(item => {
                      return (
                        <a
                          key={item.title}
                          target={item?.target}
                          href={item.link}
                          className="text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          {item.title}
                        </a>
                      )
                    })}
                  </div>
                  <div>
                    <a
                      href={buttonLinks.login}
                      className="mb-2 w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow text-base font-medium text-indigo-600 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                    <a
                      href={buttonLinks.signup}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Sign up
                    </a>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  )
}
