import React from "react"
import { buttonLinks } from "../internalConfig"
import AddToSlackButton from "../buttons/AddToSlack"

import { footer } from "../internalConfig"

export default function Footer() {
  return (
    <footer className="bg-gray-50">
      <div className="border-t-4 border-gray-100 bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
            <span className="block">
              Ready to set up your first cloud account?
            </span>
            <span className="block text-transparent bg-clip-text bg-gradient-to-br from-blue-500 to-indigo-700">
              Start your free trial today.
            </span>
          </h2>
          <AddToSlackButton />
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {footer.map(item => (
            <div key={item.title} className="px-5 py-2">
              <a
                href={item.link}
                target={item.target}
                className="text-base text-gray-500 hover:text-gray-900"
              >
                {item.title}
              </a>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-sm text-gray-400">
          Get in touch on{" "}
          <a
            className="text-gray-500 hover:text-gray-900"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/adamcharvat"
          >
            Twitter
          </a>{" "}
          or email{" "}
          <a
            className="text-gray-500 hover:text-gray-900"
            href="mailto:hello@cloudspendbot.com"
          >
            hello@cloudspendbot.com
          </a>
          .
        </p>
      </div>
    </footer>
  )
}
